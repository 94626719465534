/* Estilo para o ícone do modal */

.my-sweetalert-popup {
  width: 300px !important;
  /* Define a largura do modal */
  font-family: 'Arial', sans-serif;
  /* Define a família de fontes */
  border-radius: 10px;
  /* Define o raio das bordas */
}

.my-sweetalert-title {
  font-size: 20px;
  /* Define o tamanho do título */
  color: #333333;
  /* Define a cor do título */
}

.my-sweetalert-content {
  font-size: 16px;
  /* Define o tamanho do conteúdo */
  color: #333333;
  /* Define a cor do conteúdo */
}

.my-sweetalert-confirm-button {
  background-color: #2c7be5 !important;
  /* Define a cor de fundo do botão de confirmação */
  border-color: #ffffff !important;
  color: #ffffff;
  /* Define a cor do texto do botão de confirmação */
  border-radius: 5px;
  /* Define o raio das bordas do botão de confirmação */
}